import React, { Fragment } from 'react';

// Components
import Title from './Title';
import ContactForm from './ContactForm';
// ... any other components you might be using such as a Button or Input components for the form

function Contact() {
  return (
    <Fragment>
        <div id="contact" className="section-wrapper">
            <div className="container">
                <div className="contact-wrapper appear-parent">
                    <Title
                        text="Get In Touch"
                        number="4"
                        className="appear-child"
                    />
                    <div className="contact-inner-wrapper">
                        <div className="contact-text-wrapper">
                            <p className="appear-child">
                            Interested in pioneering AI breakthroughs or crafting cutting-edge web projects? Let's unite our curiosity and expertise! 
                            <br></br><br></br>
                            If you're passionate about advancing research in BioAI, LLMs, RL, or ML, or if you're keen on collaborating on innovative web endeavors, I'm all ears. Drop a message—let's create something remarkable together!                            </p>
                        </div>

                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  );
}

export default Contact;
