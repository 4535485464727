import React from 'react';

function Title({ number, text }) {
  return (
    <div className="title-wrapper">
      <div className="title-number appear-child">0{number}.</div>
      <h2 className="title-text appear-child">{text}</h2>
      <div className="title-line appear-child"></div>
    </div>
  );
}

export default Title;