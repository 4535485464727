import React from 'react';

// Component imports
import NavigationHeader from './components/NavigationHeader';
import Footer from './components/Footer';
import Hero from './components/Hero';
import About from './components/About';
import Websites from './components/Websites';
import Projects from './components/Projects';
import Contact from './components/Contact';

function App() {

  const appearOnScroll = () => {
    const appearParent = document.querySelectorAll('.appear-parent');
  
    appearParent.forEach((parent) => {
      const appearChildren = parent.querySelectorAll('.appear-child');
  
      const appearOptions = {
        rootMargin: '0px',
        threshold: 0.1,
      };
  
      const appearOnScrollObserver = new IntersectionObserver((entries, appearOnScrollObserver) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          } else {
            let delay = 0;
  
            appearChildren.forEach((child) => {
              setTimeout(() => {
                child.classList.add('appear');
              }, delay);
  
              delay += 200;
            });
  
            appearOnScrollObserver.unobserve(parent);
          }
        });
      }, appearOptions);
  
      appearOnScrollObserver.observe(parent);
    });
  };
  
  window.addEventListener('load', appearOnScroll);
  window.addEventListener('scroll', appearOnScroll);

  return (
    <div>
      <NavigationHeader />
      <Hero />
      <About />
      <Websites />
      <Projects />
      <Contact />
      <Footer />
      {/* Add other components here */}
    </div>
  );
}

export default App;
