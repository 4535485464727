import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Button from './Button';

function ContactForm() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    type: {
      research: false,
      webDesign: false,
      other: false
    },

    message: ''
  });

  // Create a ref for the reCAPTCHA component
  const recaptchaRef = React.createRef();
  const recaptchaSiteKey = '6Ld8ZzYpAAAAAFKc82WyXLme2Mull_GlX8oloMpY';
  // const recaptchaSiteKey = '6Ld8ZzYpAAAAAKDBKYFcFzyY-5RYiyyoWJVkhf6z';
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (!formData.phone) {
      newErrors.phone = "Phone is required";
      isValid = false;
    }
    if (!formData.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }
    if (!formData.type.research && !formData.type.webDesign && !formData.type.other) {
      newErrors.type = "Please select at least one type:";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: { ...prevFormData.type, [name]: checked }
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const token = await recaptchaRef.current.executeAsync();
    setRecaptchaValue(token);

    if (validateForm() && token) {
      if (!recaptchaValue) {
        // Handle the case where reCAPTCHA has not been solved
        console.error('Please solve the reCAPTCHA.');
        return;
      }
      
      // Prepare form data with reCAPTCHA response
      const submissionData = {
        ...formData,
        'g-recaptcha-response': recaptchaValue,
      };

      // Here, you would send `submissionData` to your server or serverless function.
      // For example, using the fetch API:
      fetch('/api/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // Handle success response, reset form, show success message, etc.
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle errors here, show error message to the user, etc.
      });
      
      console.log(submissionData);
    }
  };

  return (
    <form className='contact-form' onSubmit={handleSubmit}>
      <input
        className='appear-child'
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        type="text"
        placeholder="Name"
        required
      />
      <input
        className='appear-child'
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        type="email"
        placeholder="Email"
        required
      />
      <input
        className='appear-child'
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
        type="tel"
        placeholder="Phone"
        required
      />
      {errors.type && <div className='error-message'><br></br>{errors.type}</div>}
      <div className='checkbox-group'>
        <label className='checkbox-label appear-child'>
          <input
            id='research-checkbox'
            name="research"
            type="checkbox"
            checked={formData.type.research}
            onChange={handleInputChange}
          />
          Research
        </label>
        <label className='checkbox-label appear-child'>
          <input
            id='web-design-checkbox'
            name="webDesign"
            type="checkbox"
            checked={formData.type.webDesign}
            onChange={handleInputChange}
          />
          Web Design
        </label>
        <label className='checkbox-label appear-child'>
          <input
            id='other-checkbox'
            name="other"
            type="checkbox"
            checked={formData.type.other}
            onChange={handleInputChange}
          />
          Other
        </label>
      </div>
      <textarea
        className='appear-child'
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        placeholder="Your Message"
        required
      />
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        //size="invisible" // reCAPTCHA v3 works as an invisible captcha
        sitekey={recaptchaSiteKey}
        // onChange={setRecaptchaValue}
      /> */}
      <Button
        className='appear-child'
        type="submit"
        text="Submit"
        padding="1rem 2rem" // Adjusted from px to rem for consistency
        fontSize="1.25rem" // Adjusted from px to rem for consistency
        full={true} // This will make the button full-width
        disabled={Object.keys(errors).length > 0}
      />
    </form>
  );
}

export default ContactForm;