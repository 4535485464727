import React from 'react';

const Logo = ({ height }) => {
  const width = height / 1.1;
  const color = "234, 132, 255";

  return (
    <div className="logo-container">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 375 375"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
        >
        <defs className="hexagon-logo">
            <g/>
            <clipPath id="4939a36f45">
                <path d="M 26.132812 0 L 348.632812 0 L 348.632812 375 L 26.132812 375 Z M 26.132812 0 " clipRule="nonzero"/>
            </clipPath>
            <clipPath id="b013fb12ed">
                <path d="M 187.382812 375 L 26.132812 281.25 L 26.132812 93.75 L 187.382812 0 L 348.632812 93.75 L 348.632812 281.25 L 187.382812 375 " clipRule="nonzero"/>
            </clipPath>
            </defs>
            <g className="hexagon-background">
            <path
                fill={`rgba(${color}, 0)`}
                d="M 187.382812 375 L 26.132812 281.25 L 26.132812 93.75 L 187.382812 0 L 348.632812 93.75 L 348.632812 281.25 L 187.382812 375"
            />
            </g>
            <g clipPath="url(#4939a36f45)">
            <g clipPath="url(#b013fb12ed)">
                <path transform="matrix(0.000000000000000046, 0.75, -0.750545, 0.000000000000000046, 348.632794, 0)" 
                    stroke={`rgb(${color})`} 
                    strokeWidth="48" 
                    strokeOpacity="1" 
                    strokeLinecap="butt" 
                    strokeLinejoin="miter" 
                    strokeMiterlimit="4" 
                    fill="none" 
                    d="M 500.000001 214.843735 L 375.000001 429.687494 L 125 429.687494 L -0.000000000000013165 214.843735 L 125 -0.0000241072 L 375.000001 -0.0000241072 L 500.000001 214.843735 "/>
            </g>
            </g>
            <g fill={`rgb(${color})`} fillOpacity="1">
            <g transform="translate(120.815665, 253.263576)">
                <g>
                <path d="M 117.625 0 L 100 0 L 100 -61.46875 L 32.96875 -61.46875 L 32.96875 0 L 15.4375 0 L 15.4375 -132.96875 L 32.96875 -132.96875 L 32.96875 -75.796875 L 100 -75.796875 L 100 -132.96875 L 117.625 -132.96875 Z M 117.625 0 "/>
                </g>
            </g>
        </g>
        </svg>
    </div>
  );
};

export default Logo;
