import React, { Fragment } from 'react'

// Components
import Title from './Title'

function About() {
  return (
    <Fragment>
        <div id="about" className="section-wrapper">
            <div className="container">
                <div className="about-wrapper appear-parent">
                    <Title
                        text="Who I am"
                        number="1"
                        className="appear-child"
                    />
                    <div className="about-inner-wrapper">
                        <div className="about-text-wrapper">
                            <p className="appear-child">
                                Hi, I'm Harry. I am a research engineer specializing in the application of Large Language Models (LLMs) within the field of BioAI. My work focuses on harnessing the power of LLMs to drive innovations in biology and immunology, pushing the boundaries of AI in healthcare and research. I have worked with the following technologies:
                            </p>
                            <ul className="about-list-wrapper appear-child">
                                <li>Python</li>
                                <li>PyTorch</li>
                                <li>TensorFlow</li>
                                <li>JAX</li>
                                <li>Docker</li>
                                <li>GCP</li>
                            </ul>
                        
                            <p className="appear-child">
                                Beyond the realm of BioAI, my adventures in web development have been about creating digital 
                                canvases where startups and small businesses can paint their dreams. With tools like <a className="link-underline" target="_blank" rel="noopener noreferrer" href="https://wordpress.org">WordPress</a>, the PERN stack, <a className="link-underline" target="_blank" rel="noopener noreferrer" href="https://webflow.com">Webflow</a>, and <a className="link-underline" target="_blank" rel="noopener noreferrer" href="https://www.shopify.com">Shopify</a>, I've helped turn entrepreneurial visions into virtual realities.
                            </p>

                        </div>

                        <div className="about-image-wrapper appear-child">
                            <div className="about-image-inner-wrapper">
                                <div className="about-image-box"></div>
                                <img className="about-image" src="/images/me.jpg" alt="Harry Wilkinson" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </Fragment>
  );
}

export default About