import React, { Fragment, useState, useEffect } from 'react';

// Components
import Button from './Button';
import Logo from './Logo';

function NavigationHeader() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // toggle mobile navigation
  useEffect(() => {
    const toggle = document.querySelector('.toggle');
    const mobileNav = document.querySelector('.mobile-navigation');
    const navigationLinks = document.querySelectorAll('.navigation-link, .mobile-navigation-item');

    const closeMobileNav = () => {
      if (isMobileNavOpen) {
        mobileNav.classList.remove('open');
        toggle.classList.remove('open');
        setIsMobileNavOpen(false);
      }
    };

    const toggleMobileNav = () => {
      if (isMobileNavOpen) {
        mobileNav.classList.remove('open');
        toggle.classList.remove('open');
        setIsMobileNavOpen(false);
      } else {
        mobileNav.classList.add('open');
        toggle.classList.add('open');
        setIsMobileNavOpen(true);
      }
    };

    // Add event listeners
    toggle.addEventListener('click', toggleMobileNav);
    navigationLinks.forEach(link => {
      link.addEventListener('click', closeMobileNav);
    });
  
    // Cleanup function
    return () => {
      toggle.removeEventListener('click', toggleMobileNav);
      navigationLinks.forEach(link => {
        link.removeEventListener('click', closeMobileNav);
      });
    };

  }, [isMobileNavOpen]);

  // close mobile navigation when clicking outside of it
  useEffect(() => {
    const mobileNav = document.querySelector('.mobile-navigation');
    const toggle = document.querySelector('.toggle');

    const closeMobileNav = (e) => {
      if (isMobileNavOpen && !mobileNav.contains(e.target) && !toggle.contains(e.target)) {
        mobileNav.classList.remove('open');
        toggle.classList.remove('open');
        setIsMobileNavOpen(false);
      }
    };
    
    document.addEventListener('click', closeMobileNav);
    return () => document.removeEventListener('click', closeMobileNav);
  }, [isMobileNavOpen]);

  // handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (!isMobileNavOpen) {
        const currentScrollPosition = window.pageYOffset;
        setShowNav(currentScrollPosition <= lastScrollPosition || currentScrollPosition <= 0);
        setLastScrollPosition(currentScrollPosition);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition, isMobileNavOpen]);

  return (
    <Fragment>
      <header className={`outer-header${showNav ? '' : ' slide-up'}`}>
        <div className="container container-wide appear-parent">
          <div className="header flex flex-justify">
            <div className="logo appear-child">
              <a href="#home">
                <Logo height="60" />
              </a>
            </div>
            <div>
              <div className={`desktop-navigation ${isDesktop ? '' : 'hide-navigation'}`}>
                <ul className="navigation-list flex">
                  <li className="navigation-item appear-child">
                    <a className="navigation-link" href="#about">
                      <div>
                        <span className="navigation-number">01.</span>
                        About
                      </div>
                    </a>
                  </li>
                  <li className="navigation-item appear-child">
                    <a className="navigation-link" href="#websites">
                      <div>
                        <span className="navigation-number">02.</span>
                        Websites
                      </div>
                    </a>
                  </li>
                  <li className="navigation-item appear-child">
                    <a className="navigation-link" href="#projects">
                      <div>
                        <span className="navigation-number">03.</span>
                        Projects
                      </div>
                    </a>
                  </li>
                  <li className="appear-child">
                    <Button
                        text="Contact"
                        padding="10px 24px"
                        left="1rem"
                        href="#contact"
                        newTab={false} // This will open the link in a new tab
                    />
                  </li>
                </ul>
              </div>
              <div className={`mobile-navigation-wrapper ${isDesktop ? 'hide-navigation' : ''}`}>
                <div className="toggle-wrapper">
                  <div className="toggle">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </div>
                </div>
                <div className="mobile-navigation flex flex-center">
                  <div>
                    <ul className="navigation-list">
                      <li className="navigation-item">
                        <a className="navigation-link" href="#about">
                          <div>
                            <span className="navigation-number">01.</span>
                            About
                          </div>
                        </a>
                      </li>
                      <li className="navigation-item">
                        <a className="navigation-link" href="#websites">
                          <div>
                            <span className="navigation-number">02.</span>
                            Websites
                          </div>
                        </a>
                      </li>
                      <li className="navigation-item">
                        <a className="navigation-link" href="#projects">
                          <div>
                            <span className="navigation-number">03.</span>
                            Projects
                          </div>
                        </a>
                      </li>
                      <li className="navigation-item">
                        <a className="navigation-link" href="#contact">
                          <div>
                            <span className="navigation-number">04.</span>
                            Contact
                          </div>
                        </a>
                      </li>
                    </ul>
                    <Button
                        className="mobile-navigation-item"
                        text="Home"
                        padding="10px 20px"
                        href="#home"
                        newTab={false} // This will open the link in a new tab
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
}

export default NavigationHeader;