import React, { Fragment } from "react";

// Component imports
import Button from "./Button";
import Helix from "./Helix";

function Hero() {
    return (
        <Fragment>
            <div id="home" className="container appear-parent">
                <div className="hero-wrapper flex flex-center whole-page">
                    <div className="hero-content">
                        <div className="flex flex-justify hero-outer-wrapper">
                            <div className="content-wrapper">
                                <h4 id="BioIntro" className="appear-child">
                                    Hello, there! My name is
                                </h4>
                                <h1 id="BioName" className="appear-child">
                                    Harry Wilkinson.<br></br>
                                    <span>
                                        Research Engineer in BioAI.
                                    </span>
                                </h1>
                                <p id="BioBio" className="appear-child">
                                    I work applying SOTA LLM techniques to biological data 
                                    at <a className="link-underline" target="_blank" 
                                    rel="noopener noreferrer" href="https://www.instadeep.com/">InstaDeep</a>. 
                                    Alongside my professional and personal interests in artificial intelligence, I work as a freelance web developer
                                    for startups and small businesses.
                                </p>
                                <div id="BioWork" className="button-wrapper appear-child">
                                    <Button
                                        text="About Me"
                                        padding="16px 32px"
                                        fontSize="20px"
                                        href="#about"
                                    />
                                </div>
                            </div>
                            <div id="BioHelix" className="helix-wrapper appear-child">
                                <Helix />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Hero;