import React, { Fragment } from 'react';
import { FiLinkedin, FiTwitter, FiGithub, FiInstagram } from "react-icons/fi";

function Footer() {

  return (
    <Fragment>
      <div className="socials-wrapper">
        <div className="socials-desktop-wrapper">
          <div className="container container-wide container-flat">
            <div className="flex flex-justify">
              <div className="medias-wrapper socials-section appear-parent">
                <ul className="social-section-content social-media-list">
                  <li className="hover-and-up appear-child">
                    <a href="https://www.linkedin.com/in/thehwilkinson/" target="_blank" rel="noopener noreferrer">
                      <FiLinkedin  className="social-icon"/>
                    </a>
                  </li>
                  <li className="hover-and-up appear-child">
                    <a href="https://twitter.com/hdrwilkinson1" target="_blank" rel="noopener noreferrer">
                      <FiTwitter  className="social-icon"/>
                    </a>
                  </li>
                  <li className="hover-and-up appear-child">
                    <a href="https://github.com/hdrwilkinson" target="_blank" rel="noopener noreferrer">
                      <FiGithub  className="social-icon"/>
                    </a>
                  </li>
                  <li className="hover-and-up appear-child">
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                      <FiInstagram  className="social-icon"/>
                    </a>
                  </li>
                </ul>
                <div className="up-bar-wrapper">
                  <div className="up-bar  appear-child"></div>
                </div>
              </div>
              <div className="email-wrapper socials-section  appear-parent">
                <div className="rotation-wrapper-outer">
                  <div className="rotation-wrapper-inner">
                    <div className="element-to-rotate">
                      <div className="hover-and-left appear-child">
                        <a href="mailto:harry@harrywilkinson.com">
                          harry@harrywilkinson.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="up-bar-wrapper">
                  <div className="up-bar appear-child"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="socials-mobile-wrapper">
          <div className="container container-wide container-flat">
            <div className="medias-wrapper">
              <ul className="social-section-content social-media-list">
                <li className="hover-and-up">
                  <a href="https://www.linkedin.com/in/thehwilkinson/" target="_blank" rel="noopener noreferrer">
                    <FiLinkedin  className="social-icon"/>
                  </a>
                </li>
                <li className="hover-and-up">
                  <a href="https://twitter.com/hdrwilkinson1" target="_blank" rel="noopener noreferrer">
                    <FiTwitter  className="social-icon"/>
                  </a>
                </li>
                <li className="hover-and-up">
                  <a href="https://github.com/hdrwilkinson" target="_blank" rel="noopener noreferrer">
                    <FiGithub  className="social-icon"/>
                  </a>
                </li>
                <li className="hover-and-up">
                  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <FiInstagram  className="social-icon"/>
                  </a>
                </li>
              </ul>
            </div>
            <div className="email-wrapper">
              <div className="hover-and-up">
                <a href="mailto:harry@harrywilkinson.com">
                  harry@harrywilkinson.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Footer