import React, { Fragment } from 'react'

function Helix() {
  return (
    <Fragment>
        <div className="helix" style={{ '--total': `{20}` }}>
            {[...Array(20)].map((_, i) => (
              <div className="strand" style={{ '--i': i + 1, '--total': 20 }} key={i}>
                <div className="left">
                  <div className="dot"></div>
                  <div className="line"></div>
                </div>
                <div className="right">
                  <div className="dot"></div>
                  <div className="line"></div>
                </div>
              </div>
            ))}
        </div>
    </Fragment>
  )
}

export default Helix