import React, { Fragment } from 'react'
import { FiGithub, FiExternalLink, FiZap  } from 'react-icons/fi';


import Title from './Title';

function ProjectBox({ title, description, techStack, backgroundImage, githubLink, externalLink }) {
    return (
        <div className="project-box appear-child" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="icon-links">
                <FiZap className="icon lightbulb-icon" /> {/* Lightbulb icon */}
                <div className="links-right">
                    {githubLink && <a href={githubLink} target="_blank" rel="noopener noreferrer"><FiGithub className="icon social-icon" /></a>}
                    {externalLink && <a href={externalLink} target="_blank" rel="noopener noreferrer"><FiExternalLink className="icon social-icon" /></a>}
                </div>
            </div>
            <div className="project-content">
                <h3 className="project-title">{title}</h3>
                <p className="project-description">{description}</p>
                <div className="tech-stack">
                    {techStack.map((tech, index) => (
                        <span key={index} className="tech-stack-item">{tech}</span>
                    ))}
                </div>
            </div>
        </div>
    );
  }

  function ProjectGrid() {
    // Your project data would go here
    const projects = [
        {
          id: 1,
          title: 'Integrating Algolia Search with WordPress Multisite',
          description: 'Building a custom multisite compatible WordPress plugin to build global search with Algolia.',
          techStack: ['Algolia', 'WordPress', 'PHP'],
          backgroundImage: 'url-to-your-image-1.jpg',
          githubLink: 'https://github.com/your-username/project-1',
          externalLink: 'https://example.com/project-1'
        },
        {
          id: 2,
          title: 'Time to Have More Fun',
          description: 'A single page web app for helping me choose where to travel, built with Next.js, Firebase, and Tailwind CSS.',
          techStack: ['Next.js', 'Firebase', 'Tailwind CSS'],
          backgroundImage: 'url-to-your-image-2.jpg',
          githubLink: 'https://github.com/your-username/project-2',
          externalLink: 'https://example.com/project-2'
        },
        {
          id: 3,
          title: 'Building a Headless Mobile App CMS From Scratch',
          description: 'Find out how we built a custom headless CMS with Node, Express, and Firebase for a project at Upstatement.',
          techStack: ['Node', 'Express', 'Firebase', 'Vue'],
          backgroundImage: 'url-to-your-image-3.jpg',
          githubLink: 'https://github.com/your-username/project-3',
          externalLink: 'https://example.com/project-3'
        },
    ];
  
    return (
      <div className="project-grid">
        {projects.map(project => (
          <ProjectBox key={project.id} {...project} />
        ))}
      </div>
    );
  }

function Projects() {
    return (
        <Fragment>
            <div id="projects" className="section-wrapper">
                <div className="container">
                    <div className="project-wrapper appear-parent">
                        <Title
                            text="Personal Projects"
                            number="3"
                            className="appear-child"
                        />

                        <ProjectGrid />

                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Projects;
