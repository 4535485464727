import React, { Fragment } from 'react'

import { FiGithub, FiExternalLink } from 'react-icons/fi';

import Title from './Title';

function WebsiteBox({ tag, title, description, direction, techStack, image, githubLink, externalLink }) {

    const boxClassNames = `website-box appear-child align-${direction}`;
    return (
        <div className={boxClassNames}>
            <div className="website-content">
                <h4 className="website-tag">{tag}</h4>
                <h3 className="website-title">{title}</h3>
                <a href={externalLink} target="_blank" rel="noopener noreferrer">
                    <div className="website-image">
                        <img src={`${process.env.PUBLIC_URL}/images/websites/${image}`} alt={title} />
                    </div>
                </a>
                <p className="website-description">{description}</p>
                <div className="tech-stack">
                {techStack.map((tech, index) => (
                    <span key={index} className="tech-stack-item">{tech}</span>
                ))}
                </div>
                <div className="website-links">
                {githubLink && <a href={githubLink} target="_blank" rel="noopener noreferrer"><FiGithub /></a>}
                {externalLink && <a href={externalLink} target="_blank" rel="noopener noreferrer"><FiExternalLink /></a>}
                </div>
            </div>
        </div>
    );
}

function WebsiteGrid() {
    const websites = [
        {
            tag: "Website Launch & Web3 Integration",
            title: "The Fracture",
            description: "A pioneering platform in the Web3 space, offering immersive and interactive experiences through blockchain technology and digital art.",
            techStack: ["Next.js", "Web3 Integration", "Interactive UI"],
            image: "the-fracture.png",
            externalLink: "https://thefracture.io",
        },
        {
            tag: "Website Extension",
            title: "Alice Stephenson",
            description: "A dynamic legal professional offering innovative legal services and advocacy with a focus on technology and diversity.",
            techStack: ["Webflow", "SEO Optimization", "Responsive Design"],
            image: "alice-stephenson.png",
            externalLink: "https://www.alicestephenson.com",
        },
        {
            tag: "Website Design and Development",
            title: "The Evolution Project",
            description: "A cutting-edge venture into the world of NFTs, blending art and technology to create unique digital experiences.",
            techStack: ["React", "Web3 Integration", "SEO Optimization"],
            image: "evolution-project.png",
            externalLink: "https://evolutionproject.io",
        },
        {
            tag: 'Website and Paid-Search',
            title: 'Select Living Group',
            description: 'Select Living Group transforms outdoor spaces with its high-quality, innovative landscaping products, offering an effortless blend of style and durability for the ultimate garden makeover.',
            techStack: ['Shopify', 'Google Ads', 'Facebook Ads', 'Google Analytics'],
            image: 'select-living-group.png',
            externalLink: 'https://selectlivinggroup.com',
        },
    ];

    return (
        <div className="website-grid">
            {websites.map((website, index) => {
                // Determine the direction based on even/odd index
                const direction = index % 2 === 0 ? 'left' : 'right';
                return (
                    <WebsiteBox key={website.id} {...website} direction={direction} />
                );
            })}
        </div>
    );
}

function Websites() {
  return (
    <Fragment>
        <div id="projects" className="section-wrapper">
            <div className="container">
                <div className="project-wrapper appear-parent">
                    <Title
                        text="Web Development"
                        number="2"
                        className="appear-child"
                    />

                    <WebsiteGrid />

                </div>
            </div>
        </div>
    </Fragment>
  );
}

export default Websites;
