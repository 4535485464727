import React from 'react';

function Button({ padding, left, text, fontSize, className, onClick, href, newTab, type, full, disabled, ...rest }) {
  const styles = {
    padding,
    left: left ? '20px' : undefined,
    fontSize: fontSize || '1rem',
    width: full ? '100%' : undefined, // Use undefined instead of 'auto' to avoid overriding styles
  };
  
  const target = newTab ? "_blank" : "_self";
  const buttonClassName = `button ${className}`; // Combine the 'button' class with any passed 'className'


  return (
    <div className="button-wrapper" style={{ width: full ? '100%' : 'auto' }}>
      {href ? (
        <a href={href} target={target} {...rest}>
          <button className={buttonClassName} style={styles} type={type} onClick={onClick} disabled={disabled}>
            {text}
          </button>
        </a>
      ) : (
        <button className={buttonClassName} style={styles} type={type} onClick={onClick} disabled={disabled}>
          {text}
        </button>
      )}
    </div>
  );
}

export default Button;